import React from "react";
import { Banner, Layout } from "~components";

const NotFoundPage = () => (
  <>
    <Layout>
      <Banner text="404" />
    </Layout>
  </>
);

export default NotFoundPage;
